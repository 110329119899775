<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset Password</h4>
                  <p>Please enter your new password.</p>
                </div>
                <vs-input
                  v-validate="'required|min:6|max:32'"
                  name="newPassword"
                  type="password"
                  label-placeholder="New Password"
                  v-model="newPassword"
                  class="w-full mb-6"
                />

                <span class="text-danger text-sm">{{ errors.first('newPassword') }}</span>
                <vs-input
                  v-validate="'required|min:6|max:32'"
                  name="newPasswordConfirm"
                  type="password"
                  label-placeholder="New Confirm Password"
                  v-model="newPasswordConfirm"
                  class="w-full mb-8"
                />
                <span class="text-danger text-sm">{{ errors.first('newPasswordConfirm') }}</span>
                <v-spacer></v-spacer>

                <div class="flex flex-wrap justify-between mb-3">
                  <vs-button to="/pages/login" type="border">Back To Login</vs-button>
                  <vs-button :disabled="!validateForm" @click="changePassword">Save</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import request from "../neo/common/HttpRequests";

export default {
  data() {
    return {
      mailSecret: "",
      userId: "",
      newPassword: "",
      newPasswordConfirm: ""
    };
  },
  methods: {
    init() {
      this.mailSecret = this.$route.params.mailSecret;
    },
    changePassword() {
      request
        .HttpPost("/api/Authentication/ResetPassword", {
          NewPassword: this.newPassword,
          NewPasswordConfirm: this.newPasswordConfirm,
          MailSecret: this.mailSecret
        })
        .then(() => {
          this.$vs.notify({
            title: "Message",
            text: "Your password has been changed",
            color: "success"
          });
        })
        .then(() => {
          setTimeout(() => {
            this.$router.push("/pages/login");
          }, 1500);
        })
        .catch(e => {
          this.$vs
            .notify({
              title: "Error",
              text: e.data.Message || e.Message,
              color: "danger",
              time: 6000
            })
            .then(() => {
              setTimeout(() => {
                this.$router.push("/pages/login");
              }, 4000);
            });
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.newPassword &&
        this.newPasswordConfirm &&
        this.newPassword == this.newPasswordConfirm
      );
    }
  },
  mounted: function() {
    this.init();
  }
};
</script>
